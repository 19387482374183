
//http://localhost:8080/#/claim/EnquiryProgram
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  nextTick,
  h,
  computed,
} from "vue";
import { getQueryList, deleteInfo } from "@/API/claim/claimQuery";
import {
  getPayPreLst,
  getRejectToSICCO,
  postRejectToSICCO,
  getSubmitToCon,
  postSubmitToCon,
  getUser,
} from "@/API/checking/paymentPreparation";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
// import setColumnWidth from "@/utils/claim/setColumnWidth"
import downloadFile from "@/utils/claim/downloadFile";
import moment from "moment";
import { AFCandLCApproveTableData } from "@/views/Payment/Mgmt/types";
// import axios from "axios";
// const tableWidth = window.innerWidth;
const tableWidth = 840;
const columns = reactive([
  { title: "Program Code", dataIndex: "programCode", width: 180 },
  { title: "Program Name", dataIndex: "programName" },
  { title: "Program Category", dataIndex: "programCategory", width: 180 },
  { title: "Dealer Qty", dataIndex: "dealerQty", width: 100 },
  { title: "Claim Qty", dataIndex: "claimQty", width: 80 },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    align: "center",
    width: 150,
  },
  {
    title: "Operation",
    dataIndex: "checkResult",
    width: 120,
    slots: { customRender: "operation" },
  },
]);
const coverColumns = reactive([
  {
    title: "No",
    dataIndex: "programCode",
    width: 60,
    slots: { customRender: "No" },
  },
  { title: "Program Code", dataIndex: "programCode", width: 150 },
  { title: "Program Category", dataIndex: "programCategory", width: 150 },
  { title: "Qty", dataIndex: "qty", width: 80 },
]);
interface QueryListType {
  programNameCn: string | null;
  programNameEn: string | null;
  programCode: string | null;
  offerTypeIdLst: [];
  eligiblePeriod: string[];
  submissionDate: string[];
  programStatusLst: [];
}
interface SearchListType {
  dealerCode: string | null;
  model: string | null;
  checkResult: string | null;
  eligiblePeriodQueryFrom: string | null;
  eligiblePeriodQueryTo: string | null;
  dealerNameCn: string | null;
  inParagraph: string | null;
  complaintStatus: string | null;
  createdAtQueryFrom: string | null;
  createdAtQueryTo: string | null;
  current: number | 0;
  size: number | 20;
}
interface TableType {
  dealerCode: string;
  dealerNameCn: string;
  programCode: string;
  eligiblePeriodStr: string;
  offerTypeName: string;
  optionName: string;
  vinNo: string;
  model: string;
  inParagraph: string;
  payRound: string;
  checkResult: string;
  complaintStatus: string;
  predictAmount: string;
  price: string;
  createdTime: string;
  status: string;
}
export default defineComponent({
  components: {},
  setup() {
    const searchCon = ref();
    const tableHeight = ref();
    //筛选条件，各个信息框的内容
    const queryListParams = reactive({
      programNameEn: "",
      programCode: "",
    });
    //显示的表格数据
    const queryListData = ref<TableType[]>([]);
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 0,
      total: 0,
    });
    //定义初始值
    const listParams = ref<any>({
      current: 0,
      size: 20,
      programBus: [],
      programCode: "",
      programName: "",
    });
    const selectTableIds = ref<any>([]);
    const isBtnCover = ref<any>(false);
    const coverInfo = ref<any>({
      coverTit: "",
    });
    const dataSource = ref<any>([]);
    const coverDataSource = ref<any>([]);
    const sumVal = reactive({
      qtySum: 0,
    });
    const chooseTableLst = ref<any>([]);
    const programBusStr = ref<any>("");
    const programBuIdsStr = ref<any>("");
    //tableRowSelection配置项
    const rowSelection = reactive<{
      selectedRowKeys: [];
      selectedRowData: AFCandLCApproveTableData[];
      onChange: Function;
      columnWidth: string;
      fixed: boolean;
    }>({
      onChange: (selectedRowKeys: [], selectedRows: any) => {
        chooseTableLst.value = selectedRows;
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
        console.log(200, selectedRows[0]);
        /*const selectIds = ref<any[]>([])
                selectedRows.forEach((item,index) => {
                    selectIds.value.push(item.id)
                })
                selectTableIds.value = selectIds.value*/
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: "40px",
      fixed: false,
    });
    // 获取params
    const getParams = () => {
      listParams.value.current = pagination.currentPage;
      listParams.value.size = pagination.pageSize;
      // listParams.value.programBus = ['VAN'];
      listParams.value.programCode = "";
      listParams.value.programName = "";

      listParams.value.programName =
        queryListParams.programNameEn !== null &&
        queryListParams.programNameEn !== ""
          ? queryListParams.programNameEn
          : "";
      listParams.value.programCode =
        queryListParams.programCode !== null &&
        queryListParams.programCode !== ""
          ? queryListParams.programCode
          : "";
      /*queryListParams.programNameCn !== null && queryListParams.programNameCn !== '' ? listParams.programNameCn = queryListParams.programNameCn : delete listParams.programNameCn
            queryListParams.programNameEn !== null && queryListParams.programNameEn !== '' ? listParams.programNameEn = queryListParams.programNameEn : delete listParams.programNameEn
            queryListParams.programCode !== null && queryListParams.programCode !== '' ? listParams.programCode = queryListParams.programCode : delete listParams.programCode
            // queryListParams.eligiblePeriodFrom !== null && queryListParams.eligiblePeriodFrom !== '' ? listParams.eligiblePeriodFrom = queryListParams.eligiblePeriodFrom : ''
            // queryListParams.eligiblePeriodTo !== null && queryListParams.eligiblePeriodTo !== '' ? listParams.eligiblePeriodTo = queryListParams.eligiblePeriodTo : ''
            // queryListParams.submissionDateFrom !== null && queryListParams.submissionDateFrom !== '' ? listParams.submissionDateFrom = queryListParams.submissionDateFrom : ''
            // queryListParams.submissionDateTo !== null && queryListParams.submissionDateTo !== '' ? listParams.submissionDateTo = queryListParams.submissionDateTo : ''

            if (queryListParams.offerTypeIdLst.length > 0) {
                listParams.offerTypeIdLst = queryListParams.offerTypeIdLst
            }else {
                delete listParams.offerTypeIdLst
            }
            if (queryListParams.programStatusLst.length > 0) {
                listParams.programStatusLst = queryListParams.programStatusLst
            }else {
                delete listParams.programStatusLst
            }
            if (queryListParams.eligiblePeriod.length > 0) {
                listParams.eligiblePeriodFrom = queryListParams.eligiblePeriod[0]
                listParams.eligiblePeriodTo = queryListParams.eligiblePeriod[1]
            }else {
                delete listParams.eligiblePeriodFrom
                delete listParams.eligiblePeriodTo
            }
            if (queryListParams.submissionDate.length > 0) {
                listParams.submissionDateFrom = queryListParams.submissionDate[0]
                listParams.submissionDateTo = queryListParams.submissionDate[1]
            }else {
                delete listParams.submissionDateFrom
                delete listParams.submissionDateTo
            }*/
    };
    //根据筛选框信息，查询表格
    const getTableData = (current: number, size: number) => {
      getParams();
      //console.log(300, listParams.value);
      getPayPreLst(listParams.value).then((res) => {
        //console.log(298, res.data);
        queryListData.value = res.data;
        pagination.total = res.totalNum;
      });
    };
    //点击search事件
    const getTableDataClick = () => {
      pagination.currentPage = 0;
      getTableData(0, pagination.pageSize);
    };
    getTableData(0, pagination.pageSize);

    const getUserInfo = () => {
      const buNameLst = ref<any>([]);
      const buIdLst = ref<any>([]);
      getUser().then((res) => {
        const buLst = ref<any>([]);
        buLst.value = res.buList.filter((item: any) => {
          return item.parentId === null;
        });
        buLst.value.forEach((i: any, ind: any) => {
          buNameLst.value.push(i.nameEn);
          buIdLst.value.push(i.id);
        });
        console.log(328, buNameLst.value.join(","));
        listParams.value.programBus = buNameLst.value;
        programBusStr.value = buNameLst.value.join(",");
        programBuIdsStr.value = buIdLst.value.join(",");
        getTableData(pagination.currentPage, pagination.pageSize);
      });

      // console.log(481,listParams.value)
      // console.log(339,buNameLst.value)
    };
    getUserInfo();

    const clearQueryListParams = () => {
      queryListParams.programNameEn = "";
      queryListParams.programCode = "";
      pagination.pageSize = 20;
      pagination.currentPage = 0;
      pagination.total = 0;
    };
    //更改分页组件，包括：直接跳转到指定页，上一页下一页
    const changePagination = (page: number, pagesize: number) => {
      pagination.pageSize = pagesize;
      pagination.currentPage = page > 0 ? page - 1 : 0;
      getTableData(pagination.currentPage, pagination.pageSize);
    };
    //更改每页size
    const changePageSize = (page: number, pagesize: number) => {
      pagination.pageSize = pagesize;
      pagination.currentPage = page > 0 ? page - 1 : 0;
      getTableData(pagination.currentPage, pagination.pageSize);
    };
    //下载
    const exportResult = () => {
      getParams();
      const timeStr = moment(new Date()).format("YYYYMMDDhhmmss");
      console.log(timeStr);
      const paramsConfig = {
        url: `/claimapi/checking/program/export`,
        method: "post",
        params: listParams.value,
        fileName: `Export_Program_Enquiry_${timeStr}.xlsx`,
      };
      downloadFile(paramsConfig, "application/vnd-excel; char-set=UTF-8");
    };
    nextTick(() => {
      (document.getElementsByClassName("table-box")[0] as any).style.height =
        window.innerHeight - 220 - searchCon.value.$el.scrollHeight + "px";
      const height = (document.getElementsByClassName("table-box")[0] as any)
        .style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf("px")));
      tableHeight.value = heightNum - 110;
    });
    //关闭Cover
    const closeBtnCover = () => {
      isBtnCover.value = false;
      dataSource.value.length = 0;
    };
    //打开cover
    const openBtnCover = (type: any) => {
      isBtnCover.value = true;
      if (type === "rejecttosicco") {
        coverInfo.value.coverTit = "Reject To SICCO";
        coverInfo.value.type = "rejecttosicco";
      } else if (type === "submittocontrolling") {
        coverInfo.value.coverTit = "Submit To Controlling";
        coverInfo.value.type = "submittocontrolling";
      }
    };
    const dataLen = computed(() => {
      return dataSource.value.length - 1;
    });
    const renderNo = ({ index }: any) => {
      const obj = {
        children: index + 1,
        props: {} as any,
      };

      if (index === dataLen.value) {
        return h("span", { class: "weight" }, `Total`);
      }

      return obj;
    };
    const renderQty = ({ text, index }: any) => {
      const obj = {
        children: text,
        props: {} as any,
      };

      if (index === dataLen.value) {
        // obj.props.colSpan = 0;
        return h("span", { class: "weight" }, sumVal.qtySum);
      }

      return obj;
    };
    const getRejectToSICCOData = (type: any) => {
      getParams();
      const paramer = {
        dealerCodes: [],
        confirm: type,
        offerTypeIds: [],
        progDesignIds: [],
        offerTypeAndProgramList: [],
        programBus: [],
        programCode: "",
        programName: "",
      };
      const dealerCodes = ref<any>([]);
      const offerTypeIds = ref<any>([]);
      const progDesignIds = ref<any>([]);
      const offerTypeAndProgramList = ref<any>([]);
      const programBus = ref<any>([]);
      chooseTableLst.value.forEach((item: any, index: any) => {
        dealerCodes.value.push(item.dealerCodes);
        offerTypeIds.value.push(item.offerTypeId);
        progDesignIds.value.push(item.progDesignId);
        offerTypeAndProgramList.value.push({
          progDesignId: item.progDesignId,
          offerTypeId: item.offerTypeId,
        });
        // programBus.value.push.push(item.dealerCode)
        // paramer.offerTypeIds = []
        // paramer.progDesignIds = []
        // paramer.programBus = []
      });
      console.log(dealerCodes.value);
      // paramer.dealerCodes = []
      // paramer.offerTypeIds = []
      // paramer.progDesignIds = []
      // paramer.programBus = []
      paramer.dealerCodes = dealerCodes.value;
      paramer.offerTypeIds = offerTypeIds.value;
      paramer.progDesignIds = progDesignIds.value;
      paramer.offerTypeAndProgramList = offerTypeAndProgramList.value;
      paramer.programBus = listParams.value.programBus;
      paramer.programCode = listParams.value.programCode;
      paramer.programName = listParams.value.programName;
      if (type === "N") {
        getRejectToSICCO(paramer).then((res) => {
          console.log(res);
          const dataArr = ref<any>([]);
          dataArr.value = res ? res : {};
          let sum = 0;
          res &&
            res.forEach((i: any, ind: any) => {
              if (i.qty) {
                sum += parseInt(i.qty);
              }
            });
          dataArr.value.push({ qty: sum });
          console.log(438, dataArr.value);
          coverDataSource.value = dataArr.value;
          if (type === "N") {
            openBtnCover("rejecttosicco");
          }
        });
      } else if (type === "Y") {
        postRejectToSICCO(paramer).then((res) => {
          console.log(res);
          if (type === "Y") {
            message.success(
              "Request submitted successfully. Please query result in task center"
            );
            closeBtnCover();
          }
        });
      }
    };
    const getSubbmitControlling = (type: any) => {
      getParams();
      const paramer = {
        dealerCodes: [],
        confirm: type,
        offerTypeIds: [],
        progDesignIds: [],
        offerTypeAndProgramList: [],
        programBus: [],
        programCode: "",
        programName: "",
      };
      const dealerCodes = ref<any>([]);
      const offerTypeIds = ref<any>([]);
      const progDesignIds = ref<any>([]);
      const offerTypeAndProgramList = ref<any>([]);
      const programBus = ref<any>([]);
      chooseTableLst.value.forEach((item: any, index: any) => {
        dealerCodes.value.push(...item.dealerCodeList);
        offerTypeIds.value.push(item.offerTypeId);
        progDesignIds.value.push(item.progDesignId);
        offerTypeAndProgramList.value.push({
          progDesignId: item.progDesignId,
          offerTypeId: item.offerTypeId,
        });
        // programBus.value.push.push(item.dealerCode)
        // paramer.offerTypeIds = []
        // paramer.progDesignIds = []
        // paramer.programBus = []
      });
      console.log(dealerCodes.value);
      // paramer.dealerCodes = []
      // paramer.offerTypeIds = []
      // paramer.progDesignIds = []
      // paramer.programBus = []
      paramer.dealerCodes = dealerCodes.value;
      paramer.offerTypeIds = offerTypeIds.value;
      paramer.progDesignIds = progDesignIds.value;
      paramer.offerTypeAndProgramList = offerTypeAndProgramList.value;
      paramer.programBus = listParams.value.programBus;
      paramer.programCode = listParams.value.programCode;
      paramer.programName = listParams.value.programName;
      if (type === "N") {
        getSubmitToCon(paramer).then((res) => {
          console.log(res);
          const dataArr = ref<any>([]);
          dataArr.value = res ? res : {};
          let sum = 0;
          res &&
            res.forEach((i: any, ind: any) => {
              if (i.qty) {
                sum += parseInt(i.qty);
              }
            });
          dataArr.value.push({ qty: sum });
          coverDataSource.value = dataArr.value;
          if (type === "N") {
            openBtnCover("submittocontrolling");
          }
        });
      } else if (type === "Y") {
        postSubmitToCon(paramer).then((res) => {
          if (type === "Y") {
            closeBtnCover();
            getTableData(pagination.currentPage, pagination.pageSize);
            if (res.code === "0") {
              message.success(res.data);
            }
          }
        });
      }
    };

    /*const renderRejected = ({ text, index }: any) => {
            const obj = {
                children: text,
                props: {} as any,
            };

            if (index === dataLen.value) {
                // obj.props.colSpan = 0;
                return h('span', { class: 'weight' }, sumVal.rejectedSum);
            }

            return obj;
        };
        const renderPending = ({ text, index }: any) => {
            const obj = {
                children: text,
                props: {} as any,
            };

            if (index === dataLen.value) {
                // obj.props.colSpan = 0;
                return h('span', { class: 'weight' }, sumVal.pendingSum);
            }

            return obj;
        };
        const renderTotal = ({ text, index }: any) => {
            const obj = {
                children: text,
                props: {
                    class: 'weight',
                } as any,
            };

            if (index === dataLen.value) {
                // obj.props.colSpan = 0;
                return h('span', { class: 'weight' }, sumVal.totalSum);
            }

            return obj;
        };*/

    return {
      getUserInfo,
      programBusStr,
      programBuIdsStr,
      getSubbmitControlling,
      coverDataSource,
      getRejectToSICCOData,
      dataSource,
      renderQty,
      renderNo,
      coverInfo,
      openBtnCover,
      closeBtnCover,
      isBtnCover,
      selectTableIds,
      rowSelection,
      searchCon,
      tableWidth,
      tableHeight,
      coverColumns,
      columns,
      queryListParams,
      queryListData,
      getParams,
      getTableDataClick,
      getTableData,
      exportResult,
      pagination,
      changePagination,
      changePageSize,
      clearQueryListParams,
    };
  },
});
